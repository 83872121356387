import { createModalWrapper } from './createModalWrapper'
import { createIframeTemplate } from '../components/iframe/template'

// Get or create iFrame
const createIframe = async () => {
  const iframe = document.getElementById('PFCFrame')
  const iframeWrapper = createModalWrapper()

  if (!iframe) {
    const iFrameMarkup = createIframeTemplate()
    iframeWrapper.insertAdjacentHTML('beforeend', iFrameMarkup)
  }
}

export default createIframe
