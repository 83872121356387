import { QuestionSign, Truck, DownArrow } from '../../icons'
import { TRY_BEFORE_YOU_BUY } from '../../../localization/eligible-container'
import {
  APPLICABLE_FOR_SINGAPOREANS_ONLY,
  AT_CHECKOUT,
  PAYMENT_OPTIONS,
  ON_THE_SPOT_RETURNS,
  TRY_AT_HOME_AS_SOON_AS,
  UNSURE_ABOUT_SIZE,
  FOR_SIZES
} from '../../../localization/tbyb-home'
import styles from './styles.css'
import { EligibilityType } from '../../../types'
import green_checkbox from '../../../assets/green-checkbox.svg'
import tick from '../../../assets/tick.svg'

export const getTbybHomeMarkup = ({
  isShowIcon,
  slotTime,
  availability
}: {
  isShowIcon?: boolean
  slotTime: string
  availability?: EligibilityType[]
}) => {
  const titleMarkup = `
    <div class="tbyb-home-eligible-mb-1 tbyb-home-eligible-text-left pfc-tbyb-size-flex">
      Eligible for
      <span class="tbyb-home-eligible-text-extrabold tbyb-home-eligible-text-green">${TRY_BEFORE_YOU_BUY}</span>
      <span>${AT_CHECKOUT}</span> <span class="tbyb-home-eligible-text-extrabold tbyb-home-eligible-text-green">${FOR_SIZES}</span>
      ${DownArrow('tbyb-home-eligible-show-sizes-icon')}
    </div>  
  `

  const sizesMarkup = `
    <div class="pfc-modal-sizes tbyb-home-eligible-mb-1 tbyb-home-eligible-text-left pfc-display-none">
      ${availability
        ?.map((prod: any) => {
          return `<div class="pfc-size">
        <img src=${green_checkbox} style="margin-right:8px;" alt='no hassle of returns' width = "16px" />
          ${prod.option1 ? prod.option1 : ''}${prod.option2 ? ', ' + prod.option2 : ''}${
            prod.option3 ? ', ' + prod.option3 : ''
          }
        </div>`
        })
        .join('')} 
    </div>  
  `

  const infoMarkup = `
    <div class="tbyb-home-eligible-text-bold tbyb-home-eligible-text-black tbyb-home-eligible-mb-3 tbyb-home-eligible-text-left">
      ${TRY_AT_HOME_AS_SOON_AS} ${slotTime}
    </div>
    <div class="tbyb-home-eligible-text-sm">
      <div class="tbyb-home-eligible-flex tbyb-home-eligible-items-center tbyb-home-eligible-mb-2">
       <img src=${tick} style="margin-right:8px;width:16px;height:16px;" alt='no hassle of returns'/>
        <div>
          ${UNSURE_ABOUT_SIZE}
        </div>
      </div>
      <div class="tbyb-home-eligible-flex tbyb-home-eligible-items-center tbyb-home-eligible-mb-2">
       <img src=${tick} style="margin-right:8px;width:16px;height:16px;" alt='no hassle of returns'/>
        <div>
          ${ON_THE_SPOT_RETURNS}
        </div>
      </div>
      <div class="tbyb-home-eligible-flex tbyb-home-eligible-items-center tbyb-home-eligible-mb-2">
       <img src=${tick} style="margin-right:8px;width:16px;height:16px;" alt='no hassle of returns'/>
        <div>
          ${PAYMENT_OPTIONS}
        </div>
      </div>
    </div>

    <div class="tbyb-home-eligible-text-sm tbyb-home-eligible-flex tbyb-home-eligible-items-center tbyb-home-eligible-mt-3">
        <span>${APPLICABLE_FOR_SINGAPOREANS_ONLY}</span>
        ${QuestionSign('tbyb-home-eligible-show-modal-icon')}
    </div>
  `

  if (isShowIcon) {
    return `
    <style>${styles.toString()}</style>
    <div class="tbyb-home-eligible-container">
      <div class="tbyb-home-eligible-mr-2">${Truck()}</div>
      <div class="tbyb-home-relative">
        ${titleMarkup}
        ${sizesMarkup}
        ${infoMarkup}
      </div>
    </div>
  `
  }

  return `
    <style>${styles.toString()}</style>
    <div class="tbyb-home-eligible-container-no-icon">
      ${infoMarkup}
    </div>
  `
}
