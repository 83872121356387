import { QuestionSign } from '../../icons'
import { TRY_BEFORE_YOU_BUY } from '../../../localization/eligible-container'
import getInstallmentAmount from '../../../utils/getInstallmentAmount'
import styles from './styles.css'

export const getRelyListingMarkup = (productPrice: any, isCentered: any) => {
  const installmentAmount = getInstallmentAmount(productPrice)
  return `
    <style>${styles.toString()}</style>
    <div class="tbyb-banner-container tbyb-banner-flex tbyb-banner-items-center ${
      isCentered ? 'tbyb-justify-center' : ''
    }">
      <div class="tbyb-banner-text-left tbyb-banner-flex tbyb-banner-items-center tbyb-banner-flex-wrap">
        <div class="tbyb-banner-mr-1 tbyb-banner-flex tbyb-banner-items-center tbyb-banner-flex-wrap tbyb-banner-gap ${
          isCentered ? 'tbyb-justify-center' : ''
        }">
          <span>or 3 installments of</span>
          <span class="tbyb-banner-text-extrabold"> ${'$' + installmentAmount.toFixed(2)} SGD</span>
          <span> with</span> <span class="tbyb-banner-text-extrabold tbyb-banner-text-green tbyb-banner-items-center tbyb-banner-whitespace-nowrap tbyb-banner-lineheight-1">${TRY_BEFORE_YOU_BUY}</span>
        </div>        
    </div>  
    </div>
  `
}

export const getRelyProductPageMarkup = (productPrice: any) => {
  const installmentAmount = getInstallmentAmount(productPrice)
  return `
    <style>${styles.toString()}</style>
    <div class="tbyb-banner-container tbyb-banner-container-pdp tbyb-banner-flex tbyb-banner-items-center">
      <div class="tbyb-banner-text-left tbyb-banner-flex tbyb-banner-flex-direction-column tbyb-banner-flex-wrap">
        <div class="tbyb-banner-mr-1 tbyb-banner-flex tbyb-banner-flex-wrap tbyb-banner-gap">
          <span>
            or
          </span>
          <span class="tbyb-banner-text-extrabold">
            ${' $' + installmentAmount.toFixed(2)} SGD
          </span>
          <span>x 3 monthly installments.</span>
        </div>
        <div class="tbyb-banner-mr-1 tbyb-banner-flex tbyb-banner-flex-wrap tbyb-banner-gap tbyb-banner-items-center">
          <span> No interest or fees with </span>
          <span class="tbyb-banner-text-extrabold tbyb-banner-flex tbyb-banner-items-center tbyb-banner-text-green tbyb-banner-lineheight-1">
            ${TRY_BEFORE_YOU_BUY}${QuestionSign('eligible-show-modal-icon')}
          </span>
        </div>
      </div>
    </div>
  `
}
