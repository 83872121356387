import { comparePickupTimes } from '.'
import { PickupTime } from '../types'

const getCurrentPickupParams = (pickupTimes: PickupTime[]) => {
  const currentTime = new Date(Date.now())
  const currentHours = currentTime.getHours() // Incoming pickup time should be in 24h format
  const currentMinutes = currentTime.getMinutes()

  const currentTimeSum = currentHours * 60 + currentMinutes

  const pickupTimeIsToday = pickupTimes.some(({ time: [hours, minutes] }) => hours * 60 + minutes > currentTimeSum)

  const sortedPickupTime = pickupTimes.sort(comparePickupTimes)
  const todayPickup = sortedPickupTime.find(({ time: [hours, minutes] }) => hours * 60 + minutes > currentTimeSum) // Take the earliest possible pickup time for today
  const tomorrowPickup = sortedPickupTime[0] // Take the earliest possible pickup time for tomorrow
  const pickupTime = todayPickup || tomorrowPickup

  const {
    cutoffTime,
    time: [hours, minutes]
  } = pickupTime
  const accumulatedHours = hours + Math.floor((minutes + cutoffTime) / 60)
  const accumulatedMinutes = (minutes + cutoffTime) % 60

  return {
    isToday: pickupTimeIsToday,
    time: {
      hours: accumulatedHours,
      minutes: accumulatedMinutes
    }
  }
}

export default getCurrentPickupParams
