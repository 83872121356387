import { getStandardMarkup } from '../components/eligable-modal/standard/template'
// import { getExpressMarkup } from '../components/eligable-modal/express/template'
import { createModalWrapper } from './createModalWrapper'
import RetailerType from '../enums/retailer-types'
import { getTbybHomeMarkup } from '../components/eligable-modal/tbyb-home/template'

const createEligableModal = (type?: RetailerType) => {
  const modal = document.getElementById('PFCModal')
  const iframeWrapper = createModalWrapper()
  if (!modal) {
    let modalMarkup = getStandardMarkup()
    switch (type) {
      case RetailerType.EXPRESS:
      case RetailerType.TBYB_HOME:
      default:
        // as of Wednesday 19 May, after deployment, all express retailers are now tbyb@home retailers
        // files related to getting expresss retailer markup are not yet deleted. Just unused
        modalMarkup = getTbybHomeMarkup()
        break
    }
    iframeWrapper.insertAdjacentHTML('beforeend', modalMarkup)
  }
}

export default createEligableModal
