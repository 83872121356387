import { iframeOrigin, clearCart, toggleIframe } from '../utils'
import toggleEligableContent from './toggleEligableContent'
import toggleModalWrapper from './toggleModalWrapper'

const handleIframeResponse = async (event: MessageEvent ) => {
  if (event.origin === iframeOrigin) {
    if (event.data === 'command:close-iframe') {
      toggleModalWrapper(true)
      toggleEligableContent(true)
      toggleIframe()
    }
    if (event.data === 'command:clear-cart') {
      await clearCart()
      document.location.replace('/')
    }
  }
}

export default handleIframeResponse
