import { getTbybHomeMarkup } from './tbyb-home/template'
import { getSmallSkeletonMarkup } from '../skeleton/template'
import { createEligableModal, toggleAvailableSizes, toggleEligableContent, toggleModalWrapper } from '../../utils'
import RetailerType from '../../enums/retailer-types'
import { EligibilityType } from '../../types'
import { getRetailerInfo } from '../../services/retailer-info'
import { getProductEligibility } from '../../services/product-eligibility'
import { RetailerDataType } from '../../types/retailer-data'
import { getNextSlot } from '../../utils/slots/getNextSlot'

export default class extends HTMLElement {
  loading: boolean
  retailerData: RetailerDataType | null
  productEligibilityData: EligibilityType[] | null

  constructor() {
    super()
    this.loading = true
    this.retailerData = null
    this.productEligibilityData = null
    this.attachShadow({ mode: 'open' })
    ;(this.shadowRoot as ShadowRoot).innerHTML = getSmallSkeletonMarkup()
  }

  get retailerId() {
    return this.getAttribute('retailerId')
  }
  set retailerId(value) {
    // @ts-ignore
    this.setAttribute('retailerId', value)
  }
  get noFetch() {
    return this.hasAttribute('noFetch')
  }
  set noFetch(value) {
    // @ts-ignore
    this.setAttribute('noFetch', value)
  }
  get hasNoIcon() {
    return this.hasAttribute('hasNoIcon')
  }
  set hasNoIcon(value) {
    // @ts-ignore
    this.setAttribute('hasNoIcon', value)
  }
  get productId() {
    return this.getAttribute('productId')
  }
  set productId(value) {
    // @ts-ignore
    this.setAttribute('productId', value)
  }

  get variantId() {
    return this.getAttribute('variantId')
  }
  set variantId(value) {
    // @ts-ignore
    this.setAttribute('variantId', value)
  }

  static get observedAttributes() {
    return ['retailerId', 'variantId', 'productId', 'noFetch', 'hasNoIcon']
  }

  // Mount
  async connectedCallback() {
    if (!this.noFetch) {
      try {
        const promises: [Promise<RetailerDataType | null>, Promise<EligibilityType[] | null>] = [
          this.handleRetailerData(),
          this.handleProductEligibilityData()
        ]
        const [retailerData, productEligibilityData] = await Promise.all(promises)
        this.retailerData = retailerData
        this.productEligibilityData = productEligibilityData
        this.render({ retailerData, productEligibilityData })
        this._attachEventHandlers()
      } catch (error) {
        console.error(error.message)
        ;(this.shadowRoot as ShadowRoot).innerHTML = ''
      }
    }
    this._attachEventHandlers()
    this.upgradeProperty('retailerData')
    this.upgradeProperty('productEligibilityData')
    this.loading = false
  }

  upgradeProperty(prop: string) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.hasOwnProperty(prop)) {
      const value = (this as any)[prop]
      delete (this as any)[prop]
      ;(this as any)[prop] = value
    }
  }

  handleToggleModal() {
    toggleEligableContent()
    toggleModalWrapper()
  }

  handleClose() {
    toggleEligableContent(true)
    toggleModalWrapper(true)
  }

  handleToggleSizes() {
    toggleAvailableSizes()
  }

  handleProductEligibilityData = () =>
    new Promise<EligibilityType[] | null>((resolve, reject) => {
      if (this.productId && this.variantId && !this.noFetch) {
        getProductEligibility(this.productId, this.variantId)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        resolve(null)
      }
    })

  handleRetailerData = () =>
    new Promise<RetailerDataType | null>((resolve, reject) => {
      if (this.retailerId && !this.noFetch && !this.retailerData) {
        getRetailerInfo(this.retailerId)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      } else {
        resolve(this.retailerData)
      }
    })

  _attachEventHandlers() {
    const closeButton = document?.querySelector('.pfc-modal-close-button')
    const showModalIcon = this.shadowRoot?.querySelector('.pfc-show-modal-icon-bg')
    const showSizesIcon = this.shadowRoot?.querySelector('.tbyb-home-eligible-show-sizes-icon')
    closeButton?.addEventListener('click', this.handleClose)
    showModalIcon?.addEventListener('click', this.handleToggleModal)
    showSizesIcon?.addEventListener('click', this.handleToggleSizes)
  }

  render({
    retailerData,
    productEligibilityData
  }: {
    retailerData: RetailerDataType | null
    productEligibilityData?: EligibilityType[] | null
  }) {
    const atLeastOneVariantAvailable = productEligibilityData?.some((el) => el.enable)
    const eligibleProducts = productEligibilityData?.filter((el) => el.enable)
    const isRetailerTypeHomeOrExpress =
      retailerData?.type === RetailerType.EXPRESS || retailerData?.type === RetailerType.TBYB_HOME
    const isProductDisable = isRetailerTypeHomeOrExpress && !atLeastOneVariantAvailable
    const isRetailerDisabled = retailerData?.enabled === false

    let markup = '' //getStandardMarkup()
    if (isProductDisable || isRetailerDisabled) markup = ''
    else if (isRetailerTypeHomeOrExpress)
      markup = getTbybHomeMarkup({
        isShowIcon: !this.hasNoIcon,
        slotTime: getNextSlot(retailerData?.slots),
        availability: eligibleProducts
      })
    ;(this.shadowRoot as ShadowRoot).innerHTML = markup

    createEligableModal(retailerData?.type)
  }
}
