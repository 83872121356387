// Clear all cart's items
const clearCart = async () => {
  try {
    fetch(`${window.location.origin}/cart/clear.js`)
  } catch (error) {
    console.warn('Error during clearing the cart')
  }
}

export default clearCart
