import { getDayName } from '../../utils'
import isTomorrow from 'date-fns/isTomorrow'

export const getNextSlot = (slots: string[] | undefined) => {
  if (!Array.isArray(slots)) return ''
  const firstAvaliableDay = new Date(slots[0])
  const isAvaliableDayTomorrow = isTomorrow(firstAvaliableDay)
  const tryOnAtHomeTimeText = isAvaliableDayTomorrow ? 'tomorrow' : getDayName(firstAvaliableDay)
  return `${tryOnAtHomeTimeText} from 1pm`
}
