import { getMarkup } from './template'
import { getBigSkeletonMarkup } from '../skeleton/template'
import {
  createIframe,
  iframeURL,
  sendDataToIframe,
  handleIframeResponse,
  createEligableModal,
  toggleIframe,
  toggleModalWrapper,
  toggleEligableContent
} from '../../utils'
import { getRetailerInfo } from '../../services/retailer-info'
import { RetailerDataType } from '../../types/retailer-data'

export default class extends HTMLElement {
  loading: boolean
  retailerData: RetailerDataType | null
  dataIsSentToIframe: boolean

  constructor() {
    super()
    this.loading = true
    this.retailerData = null
    this.dataIsSentToIframe = false
    this.attachShadow({ mode: 'open' })
    ;(this.shadowRoot as ShadowRoot).innerHTML = getBigSkeletonMarkup()
  }

  get retailerId() {
    return this.getAttribute('retailerId')
  }
  set retailerId(value) {
    // @ts-ignore
    this.setAttribute('retailerId', value)
  }
  get apiUrl() {
    return this.getAttribute('apiUrl')
  }
  set apiUrl(value) {
    // @ts-ignore
    this.setAttribute('apiUrl', value)
  }
  get logoSrc() {
    return this.getAttribute('logoSrc')
  }
  set logoSrc(value) {
    // @ts-ignore
    this.setAttribute('logoSrc', value)
  }
  // Observables
  static get observedAttributes() {
    return ['retailerId', 'logoSrc', 'apiUrl']
  }

  async connectedCallback() {
    await this.handleRetailerData()
    this.render()
    this._passProps()
    this._attachEventHandlers()
    this._attachPreloadLink()
  }

  handleClickButton = () => {
    toggleIframe()
    toggleModalWrapper()
    const iframe = document.getElementById('PFCFrame') as HTMLIFrameElement
    if (!this.dataIsSentToIframe) {
      sendDataToIframe({
        additionalData: {
          retailerData: this.retailerData,
          retailerId: this.retailerId as string,
          logoSrc: this.logoSrc,
          apiUrl: this.apiUrl || null,
          clickedOutisde: false
        },
        iframeElem: iframe
      })
      this.dataIsSentToIframe = true
    }
  }

  handleIframeClose = () => {
    toggleModalWrapper(true)
    toggleEligableContent(true)
    toggleIframe()
    const iframe = document.getElementById('PFCFrame') as HTMLIFrameElement
    sendDataToIframe({
      additionalData: {
        retailerData: this.retailerData,
        retailerId: this.retailerId as string,
        logoSrc: this.logoSrc,
        apiUrl: this.apiUrl || null,
        clickedOutisde: true
      },
      iframeElem: iframe
    })
  }

  attachIframeLoadEvent = () => {
    const iframe = document.getElementById('PFCFrame') as HTMLIFrameElement
    if (!this.dataIsSentToIframe) {
      iframe?.addEventListener('load', () => {
        sendDataToIframe({
          additionalData: {
            retailerData: this.retailerData,
            retailerId: this.retailerId as string,
            logoSrc: this.logoSrc,
            apiUrl: this.apiUrl || null,
            clickedOutisde: false
          },
          iframeElem: iframe
        })
      })
      this.dataIsSentToIframe = true
    }
  }

  handleClickQuestionMark() {
    toggleModalWrapper()
    toggleEligableContent()
  }

  handleClickCloseEligableModal() {
    toggleModalWrapper(true)
    toggleEligableContent(true)
  }

  handleRetailerData = async () => {
    this.loading = true
    try {
      if (this.retailerId) {
        this.retailerData = await getRetailerInfo(this.retailerId)
      }
    } catch (error) {
      console.error(error.message)
      ;(this.shadowRoot as ShadowRoot).innerHTML = ''
    }
    this.loading = false
  }
  _passProps() {
    const eligibleComponent = this.shadowRoot?.querySelector('pfc-eligible-component')
    if (eligibleComponent) {
      ;(eligibleComponent as any).render({ retailerData: this.retailerData })
    }
  }

  render = () => {
    if (this.retailerData?.enabled === false) {
      ;(this.shadowRoot as ShadowRoot).innerHTML = ''
      return false
    }
    createEligableModal(this.retailerData?.type)
    ;(this.shadowRoot as ShadowRoot).innerHTML = getMarkup(this.retailerId)
  }

  _attachEventHandlers() {
    this.shadowRoot?.querySelector('.tbyb-button')?.addEventListener('click', createIframe, { once: true })
    this.shadowRoot
      ?.querySelector('.tbyb-button')
      ?.addEventListener('click', this.attachIframeLoadEvent, { once: true })
    this.shadowRoot?.querySelector('.tbyb-button')?.addEventListener('click', this.handleClickButton)
    this.shadowRoot?.querySelector('.question-icon')?.addEventListener('click', this.handleClickQuestionMark)
    const closeButton = document?.querySelector('.pfc-modal-close-button')
    const iframeWrapper = document.querySelector('.pfc-modal-wrapper')
    closeButton?.addEventListener('click', this.handleClickCloseEligableModal)
    iframeWrapper?.addEventListener('click', this.handleIframeClose)
    window.addEventListener('message', handleIframeResponse)
  }

  _removeEventHandlers() {
    this.shadowRoot?.querySelector('.tbyb-button')?.removeEventListener('click', this.handleClickButton)
    this.shadowRoot?.querySelector('.question-icon')?.removeEventListener('click', this.handleClickQuestionMark)
    const closeButton = document?.querySelector('.pfc-modal-close-button')
    const iframeWrapper = document.querySelector('.pfc-modal-wrapper')
    closeButton?.removeEventListener('click', this.handleClickCloseEligableModal)
    iframeWrapper?.removeEventListener('click', this.handleIframeClose)
    window.removeEventListener('message', handleIframeResponse)
  }

  _attachPreloadLink() {
    const linkID = 'pfc-iframe-preload'
    if (!document.getElementById(linkID)) {
      const link = document.createElement('link')
      link.id = linkID
      link.rel = 'preload'
      link.href = `${iframeURL}`
      link.as = 'script'
      document.head.appendChild(link)
    }
  }
}
