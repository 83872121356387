export const UPPER_DESCRIPTION_1 = 'Choose'
export const UPPER_DESCRIPTION_2 =
  'at checkout to get your items delivered to Pixibo Fit Centre. Pay only if you decide to keep the item.'

export const MIDDLE_DESCRIPTION_1 = 'Fast, Free Delivery'
export const MIDDLE_DESCRIPTION_2 = "You'll have a 6 day window to try on the items at the Pixibo Fit Centre."
export const MIDDLE_DESCRIPTION_3 = 'Pay $0 upfront'
export const MIDDLE_DESCRIPTION_4 =
  'Pay only for the items you decide to keep. All major debit and credit cards accepted.'
export const MIDDLE_DESCRIPTION_5 = 'No hassle of returns'
export const MIDDLE_DESCRIPTION_6 = 'Change your mind? Just leave your item at the Fit Centre.'

export const LOWER_DESCRIPTION =
  'You will be notified via email and sms when items have arrived at the Fit Centre. From then, you will have 6 days to try on your items in our fitting rooms. Pay only for the items you decide to keep, at the Fit Centre. We accept all popular modes of payment.'

export const PIXIBO_URL = 'https://pixibofitcentre.com'
export const VISIT_US = 'Visit us at'
export const PIXIBO_VISIBLE_URL = 'Pixibo Fit Centre'
export const TBYB_MAP_URL = 'https://goo.gl/maps/NfjyGcgWjdh1UErPA'
export const ADDRESS_1 = '#02-42 Centrepoint Mall, Somerset, Singapore.'
export const ADDRESS_2 = 'Open daily, 10am - 10pm'
