import styles from './styles.css'

export const getBigSkeletonMarkup = () => `
 <style>${styles.toString()}</style>
 <div class="lines">
    <div class="thumb pulse"></div>
    <div class="line pulse"></div>
    <div class="line pulse"></div>
</div>
`

export const getSmallSkeletonMarkup = () => `
<style>${styles.toString()}</style>
<div class="lines">
  <div class="line pulse"></div>
  <div class="line pulse"></div>
</div>
    `

export const getSkeletonLinesMarkup = (lines = 2) => {
  const linesMarkup = new Array(lines).fill(`<div class="line pulse"></div>`).join('')

  return `
<style>${styles.toString()}</style>
<div class="lines">
  ${linesMarkup}
</div>
`
}
