const toggleAvailableSizes = (forceClose = false) => {
  console.log('TOGGLE')
  const modalWrapper = document.querySelector('pfc-eligible-component')?.shadowRoot?.querySelector('.pfc-modal-sizes') //.shadowRoot.querySelector('.pfc-modal-sizes')
  console.log(modalWrapper)
  if (modalWrapper?.classList.contains('pfc-display-block') || forceClose) {
    modalWrapper?.classList.remove('pfc-display-block')
    modalWrapper?.classList.add('pfc-display-none')
  } else {
    modalWrapper?.classList.add('pfc-display-block')
    modalWrapper?.classList.remove('pfc-display-none')
  }
}
export default toggleAvailableSizes
