import { QuestionSign } from '../../icons'
import { TRY_BEFORE_YOU_BUY } from '../../../localization/eligible-container'
import styles from './styles.css'

export const getTbybHomeMarkup = () => {
  
  return `
    <style>${styles.toString()}</style>
    <div class="tbyb-banner-container tbyb-banner-flex tbyb-banner-items-center">
      <div class="tbyb-banner-swatch tbyb-banner-bg-green tbyb-banner-mr-2 tbyb-banner-text-white">NEW</div>
      <div class="tbyb-banner-text-left tbyb-banner-flex tbyb-banner-items-center tbyb-banner-flex-wrap">
        <div class="tbyb-banner-whitespace-nowrap tbyb-banner-mr-1">
          Try-on at home with
        </div>
        <span class="tbyb-banner-text-extrabold tbyb-banner-text-green tbyb-banner-flex tbyb-banner-items-center tbyb-banner-whitespace-nowrap tbyb-banner-lineheight-1">${TRY_BEFORE_YOU_BUY} ${QuestionSign('eligible-show-modal-icon')}</span>
    </div>  
    </div>
  `

}
