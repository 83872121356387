const Button = (className?: string) => `
  <svg classes=${className} width='238' height='42' viewBox='0 0 238 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='238' height='42' rx='5' fill='#1D985B' />
    <path d='M33.2672 17.989H30V16.1657H38.8972V17.989H35.63V25.8343H33.2672V17.989Z' fill='white' />
    <path
      d='M47.1113 25.8343L45.1422 23.1409H42.969V25.8343H40.6061V16.1657H45.0255C45.9298 16.1657 46.7126 16.3085 47.3738 16.5939C48.0448 16.8794 48.5601 17.2845 48.9199 17.8094C49.2797 18.3343 49.4596 18.9558 49.4596 19.674C49.4596 20.3923 49.2748 21.0138 48.9053 21.5387C48.5455 22.0543 48.0302 22.4503 47.3592 22.7265L49.6492 25.8343H47.1113ZM47.0675 19.674C47.0675 19.1308 46.8828 18.7164 46.5133 18.4309C46.1438 18.1363 45.6041 17.989 44.8943 17.989H42.969V21.3591H44.8943C45.6041 21.3591 46.1438 21.2118 46.5133 20.9171C46.8828 20.6225 47.0675 20.2081 47.0675 19.674Z'
      fill='white'
    />
    <path
      d='M56.5275 22.4088V25.8343H54.1647V22.3812L50.212 16.1657H52.7207L55.4482 20.4613L58.1757 16.1657H60.4948L56.5275 22.4088Z'
      fill='white'
    />
    <path
      d='M73.9862 20.8066C74.5697 20.9816 75.0267 21.2762 75.3573 21.6906C75.6879 22.0958 75.8532 22.5976 75.8532 23.1961C75.8532 24.0433 75.5031 24.6971 74.803 25.1575C74.1126 25.6087 73.1014 25.8343 71.7692 25.8343H66.4892V16.1657H71.4775C72.7221 16.1657 73.6751 16.3913 74.3363 16.8425C75.0072 17.2937 75.3427 17.9061 75.3427 18.6796C75.3427 19.1492 75.2211 19.5681 74.9781 19.9365C74.7447 20.3048 74.4141 20.5948 73.9862 20.8066ZM68.8375 17.8508V20.1298H71.1858C71.7692 20.1298 72.2117 20.0331 72.5131 19.8398C72.8145 19.6464 72.9652 19.361 72.9652 18.9834C72.9652 18.6059 72.8145 18.325 72.5131 18.1409C72.2117 17.9475 71.7692 17.8508 71.1858 17.8508H68.8375ZM71.5942 24.1492C72.2165 24.1492 72.6833 24.0525 72.9944 23.8591C73.3153 23.6657 73.4757 23.3665 73.4757 22.9613C73.4757 22.1602 72.8486 21.7597 71.5942 21.7597H68.8375V24.1492H71.5942Z'
      fill='white'
    />
    <path
      d='M85.985 24.0387V25.8343H78.0796V16.1657H85.7954V17.9613H80.4279V20.0608H85.1682V21.8011H80.4279V24.0387H85.985Z'
      fill='white'
    />
    <path
      d='M90.6655 17.9613V20.5166H95.3913V22.3122H90.6655V25.8343H88.3027V16.1657H96.0184V17.9613H90.6655Z'
      fill='white'
    />
    <path
      d='M102.9 26C101.841 26 100.883 25.7836 100.027 25.3508C99.1811 24.918 98.515 24.3241 98.0288 23.5691C97.5524 22.8048 97.3142 21.9484 97.3142 21C97.3142 20.0516 97.5524 19.1998 98.0288 18.4448C98.515 17.6805 99.1811 17.082 100.027 16.6492C100.883 16.2164 101.841 16 102.9 16C103.96 16 104.913 16.2164 105.759 16.6492C106.605 17.082 107.271 17.6805 107.757 18.4448C108.244 19.1998 108.487 20.0516 108.487 21C108.487 21.9484 108.244 22.8048 107.757 23.5691C107.271 24.3241 106.605 24.918 105.759 25.3508C104.913 25.7836 103.96 26 102.9 26ZM102.9 24.0939C103.503 24.0939 104.048 23.965 104.534 23.7072C105.02 23.4401 105.399 23.0718 105.672 22.6022C105.954 22.1326 106.095 21.5985 106.095 21C106.095 20.4015 105.954 19.8674 105.672 19.3978C105.399 18.9282 105.02 18.5645 104.534 18.3066C104.048 18.0396 103.503 17.9061 102.9 17.9061C102.298 17.9061 101.753 18.0396 101.267 18.3066C100.781 18.5645 100.397 18.9282 100.115 19.3978C99.8423 19.8674 99.7062 20.4015 99.7062 21C99.7062 21.5985 99.8423 22.1326 100.115 22.6022C100.397 23.0718 100.781 23.4401 101.267 23.7072C101.753 23.965 102.298 24.0939 102.9 24.0939Z'
      fill='white'
    />
    <path
      d='M117.205 25.8343L115.236 23.1409H113.063V25.8343H110.7V16.1657H115.119C116.024 16.1657 116.807 16.3085 117.468 16.5939C118.139 16.8794 118.654 17.2845 119.014 17.8094C119.374 18.3343 119.554 18.9558 119.554 19.674C119.554 20.3923 119.369 21.0138 118.999 21.5387C118.639 22.0543 118.124 22.4503 117.453 22.7265L119.743 25.8343H117.205ZM117.161 19.674C117.161 19.1308 116.977 18.7164 116.607 18.4309C116.238 18.1363 115.698 17.989 114.988 17.989H113.063V21.3591H114.988C115.698 21.3591 116.238 21.2118 116.607 20.9171C116.977 20.6225 117.161 20.2081 117.161 19.674Z'
      fill='white'
    />
    <path
      d='M129.769 24.0387V25.8343H121.863V16.1657H129.579V17.9613H124.211V20.0608H128.952V21.8011H124.211V24.0387H129.769Z'
      fill='white'
    />
    <path
      d='M141.555 22.4088V25.8343H139.192V22.3812L135.24 16.1657H137.748L140.476 20.4613L143.203 16.1657H145.522L141.555 22.4088Z'
      fill='white'
    />
    <path
      d='M151.452 26C150.392 26 149.434 25.7836 148.578 25.3508C147.732 24.918 147.066 24.3241 146.58 23.5691C146.104 22.8048 145.865 21.9484 145.865 21C145.865 20.0516 146.104 19.1998 146.58 18.4448C147.066 17.6805 147.732 17.082 148.578 16.6492C149.434 16.2164 150.392 16 151.452 16C152.511 16 153.464 16.2164 154.31 16.6492C155.156 17.082 155.822 17.6805 156.309 18.4448C156.795 19.1998 157.038 20.0516 157.038 21C157.038 21.9484 156.795 22.8048 156.309 23.5691C155.822 24.3241 155.156 24.918 154.31 25.3508C153.464 25.7836 152.511 26 151.452 26ZM151.452 24.0939C152.054 24.0939 152.599 23.965 153.085 23.7072C153.571 23.4401 153.951 23.0718 154.223 22.6022C154.505 22.1326 154.646 21.5985 154.646 21C154.646 20.4015 154.505 19.8674 154.223 19.3978C153.951 18.9282 153.571 18.5645 153.085 18.3066C152.599 18.0396 152.054 17.9061 151.452 17.9061C150.849 17.9061 150.304 18.0396 149.818 18.3066C149.332 18.5645 148.948 18.9282 148.666 19.3978C148.393 19.8674 148.257 20.4015 148.257 21C148.257 21.5985 148.393 22.1326 148.666 22.6022C148.948 23.0718 149.332 23.4401 149.818 23.7072C150.304 23.965 150.849 24.0939 151.452 24.0939Z'
      fill='white'
    />
    <path
      d='M163.787 26C162.329 26 161.191 25.6179 160.374 24.8536C159.567 24.0893 159.164 22.9982 159.164 21.5801V16.1657H161.527V21.4972C161.527 23.2284 162.285 24.0939 163.802 24.0939C164.541 24.0939 165.105 23.8867 165.494 23.4724C165.883 23.0488 166.077 22.3904 166.077 21.4972V16.1657H168.411V21.5801C168.411 22.9982 168.003 24.0893 167.186 24.8536C166.379 25.6179 165.246 26 163.787 26Z'
      fill='white'
    />
    <path
      d='M183.249 20.8066C183.832 20.9816 184.289 21.2762 184.62 21.6906C184.95 22.0958 185.116 22.5976 185.116 23.1961C185.116 24.0433 184.766 24.6971 184.066 25.1575C183.375 25.6087 182.364 25.8343 181.032 25.8343H175.752V16.1657H180.74C181.985 16.1657 182.938 16.3913 183.599 16.8425C184.27 17.2937 184.605 17.9061 184.605 18.6796C184.605 19.1492 184.484 19.5681 184.241 19.9365C184.007 20.3048 183.677 20.5948 183.249 20.8066ZM178.1 17.8508V20.1298H180.448C181.032 20.1298 181.474 20.0331 181.776 19.8398C182.077 19.6464 182.228 19.361 182.228 18.9834C182.228 18.6059 182.077 18.325 181.776 18.1409C181.474 17.9475 181.032 17.8508 180.448 17.8508H178.1ZM180.857 24.1492C181.479 24.1492 181.946 24.0525 182.257 23.8591C182.578 23.6657 182.738 23.3665 182.738 22.9613C182.738 22.1602 182.111 21.7597 180.857 21.7597H178.1V24.1492H180.857Z'
      fill='white'
    />
    <path
      d='M191.878 26C190.42 26 189.282 25.6179 188.465 24.8536C187.658 24.0893 187.255 22.9982 187.255 21.5801V16.1657H189.618V21.4972C189.618 23.2284 190.376 24.0939 191.893 24.0939C192.632 24.0939 193.196 23.8867 193.585 23.4724C193.974 23.0488 194.168 22.3904 194.168 21.4972V16.1657H196.502V21.5801C196.502 22.9982 196.094 24.0893 195.277 24.8536C194.47 25.6179 193.337 26 191.878 26Z'
      fill='white'
    />
    <path
      d='M204.033 22.4088V25.8343H201.67V22.3812L197.717 16.1657H200.226L202.953 20.4613L205.681 16.1657H208L204.033 22.4088Z'
      fill='white'
    />
  </svg>
`

export default Button
