// Close iFrame
const closeIframe = () => {
  const iFrameElement = document.getElementById('PFCFrame')
  if (iFrameElement?.classList.contains('pfc-display-block')) {
    iFrameElement?.classList.remove('pfc-display-block')
    iFrameElement?.classList.add('pfc-display-none')
  } else {
    iFrameElement?.classList.add('pfc-display-block')
    iFrameElement?.classList.remove('pfc-display-none')
  }
}

export default closeIframe
