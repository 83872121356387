const CrossSign = (className?: string) => `
    <svg class=${className} width='19' height='20' view-box='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.3'>
        <line x1='0.353553' y1='0.646447' x2='18.0312' y2='18.3241' stroke='black' />
        <line x1='18.0312' y1='1.35355' x2='0.353557' y2='19.0312' stroke='black' />
      </g>
    </svg>
  `

export default CrossSign
