// Fetch cart's items
const fetchCart = async () => {
  try {
    const rawData = await fetch(`${window.location.origin}/cart.js`)
    return await rawData.json()
  } catch (error) {
    console.warn('Error during fetching the cart data')
  }
}

export default fetchCart
