const toggleModalWrapper = (forceClose = false) => {
  const modalWrapper = document.querySelector('.pfc-modal-wrapper')

  if (modalWrapper?.classList.contains('.pfc-display-flex') || forceClose) {
    const removeVisibleClass = () => modalWrapper?.classList.remove('pfc-display-flex')
    modalWrapper?.addEventListener('transitionend', removeVisibleClass, { once: true })
    modalWrapper?.classList.remove('pfc-visible')
  } else {
    modalWrapper?.classList.add('pfc-display-flex')
    setTimeout(() => modalWrapper?.classList.add('pfc-visible'))
  }
}

export default toggleModalWrapper
