import { CrossSign, Button } from '../../icons'
import shopping_artwork from '../../../assets/shopping_artwork.png'
import phone_artwork from '../../../assets/phone_artwork.png'
import payment_options from '../../../assets/payment_options.png'
import try_at_home_pixibo from '../../../assets/try_at_home_pixibo.png'

import styles from './styles.css'

export const getTbybHomeMarkup = () => `
<style>${styles.toString()}</style>
<div id='PFCModal' class='pfc-modal-eligable pfc-display-none'>
  <div class='pfc-modal-container'>
    <div class='pfc-modal-close-button-container'>
      <button class='pfc-modal-close-button'>${CrossSign('pfc-modal-close-icon')}</button>
    </div>
    <div class='pfc-modal-inner-container'>
      <div>
        <img src=${try_at_home_pixibo} alt='try at home with pixibo' style="width: 226px"/>
      </div>
      <div class='pfc-modal-upper-description'>
        Transform your living room into the fitting room!
      </div>
      <div class='pfc-modal-upper-description'>
      Choose ${Button('pfc-modal-fake-button-description')} at checkout to try-on at home
      </div>

      <div class='pfc-modal-middle-description'>
        <div class='pfc-modal-iconblock'>
          <div class="pfc-tbyb-home-flex pfc-tbyb-home-items-center pfc-tbyb-home-justify-center">
            <img src=${phone_artwork} alt='try first,pay later' width="174px"/>
          </div>
          <div class='pfc-modal-textblock'>
            <p class='pfc-modal-textblock-title'>Try first, Pay later</p>
            <p class='pfc-modal-textblock-text'>
              Unsure if you should get a Small or a Medium? Try both and know for sure! We will bring you all the items you want to try on. Pay only for the items you decide to keep. Our delivery partners accept all major debit and credit cards.
            </p>
          </div>
        </div>
        <div class='pfc-modal-iconblock'>
          <div class="pfc-tbyb-home-flex pfc-tbyb-home-items-center pfc-tbyb-home-justify-center">
            <img src=${shopping_artwork} alt='no hassle of returns'width="174px"/>
          </div>
          <div class='pfc-modal-textblock'>
            <p class='pfc-modal-textblock-title'>No hassle of returns</p>
            <p class='pfc-modal-textblock-text'>
              It's ok to change your mind. You get 15 minutes to try-on your items. We will wait while you decide. Doesn't fit? Not sure if it suits you? Let our delivery partner know and we'll take the items back. No extra charges.
            </p>
          </div>
        </div>
        <div class='pfc-modal-iconblock'>
          <div class="pfc-tbyb-home-flex pfc-tbyb-home-items-center pfc-tbyb-home-justify-center">
            <img src=${payment_options} alt='payment options'width="174px"/>
          </div>
          <div class='pfc-modal-textblock'>
            <p class='pfc-modal-textblock-title'>Pay in full or with instalments</p>
            <p class='pfc-modal-textblock-text'>
              All payment modes accepted. After try-on you can pay by Debit, Credit, and Pay Now. You can also pay in 3 interest-free instalments.
            </p>
          </div>
        </div>
      </div>

      <div class="pfc-tbyb-home-learn-more-link-wrapper">
        <a href="https://tbyb.sg/" target="_blank" class="pfc-tbyb-home-learn-more-link">Learn more</a>
      </div>
    </div>
  </div>
</div> 
`
