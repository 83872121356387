export const AT_CHECKOUT = 'at checkout'
export const FOR_SIZES = 'for sizes'
export const TRY_AT_HOME_AS_SOON_AS = 'Try@Home as soon as'
export const TRY_FIRST_PAY_LATER = 'Try first, pay later. Pay only for items you keep.'
export const ON_THE_SPOT_RETURNS = 'On the spot returns.'
export const EXPRESS_NEXT_DAY_DELIVERY = 'Express next day delivery to your home, Mon-Fri.'
export const APPLICABLE_FOR_SINGAPOREANS_ONLY = 'Applicable for Singapore Residents only.'
export const PAYMENT_OPTIONS = 'All payment modes accepted. Debit, Credit, PayNow, and instalments.'
export const UNSURE_ABOUT_SIZE =
  'Unsure about sizes or colours? Try multiple pieces! You only pay for the items you keep.'
