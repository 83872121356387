import { RetailerDataType } from '../types/retailer-data'
import { fetchCart } from '../utils'

export interface SendDataToIframeProps {
  additionalData: {
    retailerId: string
    logoSrc: string | null
    apiUrl: string | null
    retailerData: RetailerDataType | null
    clickedOutisde: boolean
  }
  iframeElem: HTMLIFrameElement | null
}

const sendDataToIframe = async ({ additionalData, iframeElem }: SendDataToIframeProps) => {
  const currentCart = await fetchCart()
  const composedData = {
    ...currentCart,
    additionalData
  }

  iframeElem?.contentWindow?.postMessage(JSON.stringify(composedData), '*')
}

export default sendDataToIframe
