import { PickupTime } from '../types'

const comparePickupTimes = (prevPickupTime: PickupTime, nextPickupTime: PickupTime) => {
  const prevPickupTimeSum = prevPickupTime.time[0] * 60 + prevPickupTime.time[1] + prevPickupTime.cutoffTime
  const nextPickupTimeSum = nextPickupTime.time[0] * 60 + nextPickupTime.time[1] + nextPickupTime.cutoffTime

  return prevPickupTimeSum < nextPickupTimeSum ? -1 : 1
}

export default comparePickupTimes
