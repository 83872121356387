import { createModalWrapperTemplate } from '../components/modal-wrapper/template'

export const createModalWrapper = (): Element => {
  let iframeWrapper = document.querySelector('.pfc-modal-wrapper')
  if (!iframeWrapper) {
    document.body.insertAdjacentHTML('beforeend', createModalWrapperTemplate())
    iframeWrapper = document.querySelector('.pfc-modal-wrapper')
  }
  return iframeWrapper as Element
}
