import { TRY_BEFORE_YOU_BUY } from '../../localization/entry-point'
import styles from './style.css'

export const getMarkup = (retailerId: string | null) => `
<style>${styles.toString()}</style>
  <div class='tbyb-button-container'>
      <button class='bg-tbyb-button tbyb-button' id='show-iframe-button'>
          <span class="tbyb-button-text">${TRY_BEFORE_YOU_BUY}</span>
      </button>
      <pfc-eligible-component retailerId="${retailerId}" noFetch='true' hasNoIcon="true"></pfc-eligible-component>
  </div>
`
